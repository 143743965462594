export interface ReadonlyFieldDescriptionProps {
  label: string
  description: string
}

export default function ReadonlyFieldDescription(props: Readonly<ReadonlyFieldDescriptionProps>) {
  return (
    <div>
      <input type="checkbox" checked={true} readOnly={true}/>
      <h5 className='inline-block ml-2 text-md font-bold text-slate-600'>{props.label}</h5>
      <p className='text-slate-800 italic text-sm mt-0 mb-2'>{props.description}</p>
    </div>
  )
}