import { useRouter } from "next/router"

const TOTAL_CAKES=5
const TOTAL_CONES=10
const TOTAL_BOWS=4
const TOTAL_CANDY=2

function getCake() {
  return 'cake' + Math.ceil(Math.random() * TOTAL_CAKES)
}

function getCone() {
  return 'cone' + Math.ceil(Math.random() * TOTAL_CONES)
}

function getIcon(icon: IconOptions, max: number): string {
  return icon + Math.ceil(Math.random() * max)
}

type RotateOptions = 3 | 6 | 12
type IconOptions = 'cake' | 'cone' | 'bow' | 'candy' | 'cake-logo'
type SizeOptions = 'big' | 'medium' | 'small'

export const DessertIcon = (props: {
  icon?: IconOptions, 
  rotate?: RotateOptions, 
  size?: SizeOptions, 
  className?: string
}) => {
  const router = useRouter()

  let icon = props.icon as string
  let rotate = ''

  if (props.rotate) {
    if (typeof props.rotate === 'string') {
      props.rotate = parseInt(props.rotate) as RotateOptions
    }

    rotate = props.rotate < 0 ?
      `-rotate-${Math.abs(props.rotate)}` :
      `rotate-${props.rotate}`
  }

  switch (props.icon) {
    case 'cake':
      icon = getCake()
      break
    case 'cone':
      icon = getCone()
      break
    case 'bow':
      icon = getIcon(props.icon, TOTAL_BOWS)
      break
    case 'candy':
      icon = getIcon(props.icon, TOTAL_CANDY)
      break
    case 'cake-logo':
      icon = 'cake1'
      break
    default:
      icon = Math.random() > 0.5 ? getCake() : getCone()
  }

  // Set the height (which automatically sets the width)
  let size
  switch(props.size) {
    case 'big':
      size = 'h-20'
      break
    case 'medium':
      size = 'h-14'
      break
    default:
      size = 'h-6'
  }

  return (
    <img src={`${router.basePath}/assets/images/dessertdata/${icon}.png`} className={`${size} ${rotate} inline-block ${props.className}`} />
  )
}