import Link from "next/link"

export const Cta = (props: { children: string, className?: string, location?: string}) => {
  const css = 
    "block bg-blue-600 rounded-md text-center py-5 my-6 text-white max-w-md mx-auto " + 
    (props.className ?? '')

  const location = props.location ?? '/signup/'
    
  return (
    <Link href={ location } className={ css }>
      {props.children}
    </Link>
  )
}